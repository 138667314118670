<template>
<a-layout>
    <a-layout-content>
        <a-row class="usersetting" v-bind:class="[ _mobile  ? '' : 'pc' ]">
            <a-col :span="span" :offset="offSet">
                <div style="display: table;margin: 0 auto; max-width: 394px;   width: 92%;">
                    <div style="margin-top:60px">
                        <div class="s-u-font1">기본정보 변경</div>
                        <div class="s-u-font2">이메일</div>
                        <div class="s-u-d1">
                            <a-input type="text" v-model="userEmail" disabled> </a-input>
                        </div>
                        <div class="s-u-font2">이름</div>
                        <div class="s-u-d1">
                            <a-input type="text" v-model="userName"> </a-input>
                        </div>
                        <div class="s-u-font2">기업명 혹은 소속기관</div>
                        <div class="s-u-d1">
                            <a-input type="text" v-model="userCompany"> </a-input>
                        </div>
                        <div class="s-u-font2">휴대폰 번호</div>
                        <div class="s-u-d1">
                            <a-input type="text" v-model="userPhone" v-mask="['###-###-####','###-####-####']"> </a-input>
                        </div>
                        <div class="s-u-btn1">
                            <a-button @click="onUpdateUserInfo()">
                                기본정보 변경
                            </a-button>
                        </div>
                        <!-- <div class="s-u-btn2">
                            <a-button @click="onInitCountForPaidUser()">
                                관심등록 자동 집계 오류 정보 초기화
                            </a-button>
                        </div> -->
                    </div>
                </div>

            </a-col>
        </a-row>
    </a-layout-content>
    <nav-footer></nav-footer>
</a-layout>
</template>

<script>
Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

import {
  getLicense,
  replaceAll,
  getCurrentTime,
  isMobile,
  showError,
  showSuccess
} from "../components/fnc.js";
import firebase from "firebase";
import NavFooter from "../../layouts/nav/NavFooter";
export default {
  components: {
    NavFooter
  },
  data() {
    return {
      span: 8,
      offSet: 8,
      userName: "",
      userEmail: "",
      userCompany: "",
      userPhone: ""
    };
  },
  mounted() {
    this.userName = this.getName;
    this.userEmail = this.getEmail;
    this.userPhone = this.getPhone;
    this.userCompany = this.getCompayName;
    document.getElementById("fullroot").scrollTop = 0;
    fbq("track", "PageView");
  },
  computed: {
    getName() {
      return this.$store.state.AppActiveUser.displayName;
    },
    getUid() {
      return this.$store.state.AppActiveUser.uid;
    },
    getEmail() {
      return this.$store.state.AppActiveUser.email;
    },
    getPhone() {
      return this.$store.state.AppActiveUser.phone;
    },
    getUserKey() {
      return this.$store.state.AppActiveUser.key;
    },
    getCompayName() {
      return this.$store.state.AppActiveUser.companyName;
    },
    _mobile: function() {
      var _mobile = this.$vssWidth < 1024 ? true : isMobile();
      this.span = _mobile ? 24 : 8;
      this.offSet = _mobile ? 0 : 8;
      return _mobile;
    }
  },
  methods: {
    onInitCountForPaidUserNaver() {
      var self = this;
      var sref = firebase.database().ref("auto_execution_master/");

      sref
        .orderByChild("uid")
        .equalTo(this.getUid)
        .once("value", function(sdata) {
          sdata.forEach(function(sdatas) {
            var uref = firebase
              .database()
              .ref("auto_execution_master/" + sdatas.key);

            var upData = {
              count: 0,
              mark: "-"
            };

            uref.update(upData);
          });
          self.$vs.loading.close();
        });
    },
    onInitCountForPaidUserKakao() {
      var sref = firebase.database().ref("auto_execution_master_kakao/");

      sref
        .orderByChild("uid")
        .equalTo(this.getUid)
        .once("value", function(sdata) {
          sdata.forEach(function(sdatas) {
            var uref = firebase
              .database()
              .ref("auto_execution_master_kakao/" + sdatas.key);

            var upData = {
              count: 0,
              mark: "-"
            };

            uref.update(upData);
          });
        });
    },
    onInitCountForPaidUserCoupang() {
      var sref = firebase.database().ref("auto_execution_master_coupang/");

      sref
        .orderByChild("uid")
        .equalTo(this.getUid)
        .once("value", function(sdata) {
          sdata.forEach(function(sdatas) {
            var uref = firebase
              .database()
              .ref("auto_execution_master_coupang/" + sdatas.key);

            var upData = {
              count: 0,
              mark: "-"
            };

            uref.update(upData);
          });
        });
    },
    onInitCountForPaidUserTmon() {
      var sref = firebase.database().ref("auto_execution_master_tmon/");

      sref
        .orderByChild("uid")
        .equalTo(this.getUid)
        .once("value", function(sdata) {
          sdata.forEach(function(sdatas) {
            var uref = firebase
              .database()
              .ref("auto_execution_master_tmon/" + sdatas.key);

            var upData = {
              count: 0,
              mark: "-"
            };

            uref.update(upData);
          });
        });
    },
    onInitCountForPaidUser() {
      var self = this;
      this.$vs.loading();
      this.onInitCountForPaidUserNaver();
      this.onInitCountForPaidUserKakao();
      this.onInitCountForPaidUserCoupang();
      this.onInitCountForPaidUserTmon();
      showSuccess({
        notify: self.$vs.notify,
        msg: "정상적으로 초기화 되었습니다."
      });
    },
    onUpdateUserInfo() {
      var self = this;
      var uref = firebase.database().ref("user/" + this.getUserKey);

      var upData = {
        companyname: this.userCompany,
        userPhone: this.userPhone,
        userName: this.userName
      };

      uref
        .update(upData)
        .then(function() {
          self.$store.dispatch("updateUserInfo", {
            displayName: self.userName,
            companyName: self.userCompany,
            phone: self.userPhone
          });

          showSuccess({
            notify: self.$vs.notify,
            msg: "정상적으로 저장 되었습니다."
          });
        })
        .catch(function(error) {
          showError({
            notify: self.$vs.notify,
            msg: error
          });
        });
    }
  }
};
</script>

<style>
.usersetting input {
  height: 60px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.88px;
  color: #7d7d7d;
  border-radius: 5px !important;
}

.s-u-font1 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.38px;
  color: #000000;
}

.s-u-font2 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 3;
  letter-spacing: -0.58px;
  color: #7d7d7d;
}

.s-u-d1 {
  margin-top: -5px;
}

.s-u-btn1 button,
.s-u-btn1 button:hover {
  margin-top: 30px;
  width: 100%;
  height: 60px;
  border-radius: 5px;
  background-color: #0264fb;
  color: white;
}

.s-u-btn2 button {
  margin-top: 20px;
  width: 100%;
  height: 60px;
  border-radius: 5px;
}
</style>
